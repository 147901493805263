<template>
  <div>
    <!-- 패스워드 입력 모달 -->
    <b-modal
      id="passwordModal"
      title="환전 패스워드 확인"
      ok-title="확인"
      cancel-title="취소"
      static
      backdrop="static"
      @shown="focusPasswordInput"
      @ok="confirmPassword"
      @hide="handleCancel"
      dialog-class="custom-modal-position"
    >
      <div>
        <label for="password">환전 패스워드 입력 :</label>
        <b-form-input
          type="password"
          v-model="password"
          id="password"
          placeholder="Enter your password"
          ref="passwordInput"
          @keyup.enter="confirmPassword"
        />
        <small v-if="passwordError" class="text-danger">{{ passwordError }}</small>
      </div>
    </b-modal>

    <!-- 환전 UI (패스워드가 일치해야 보여짐) -->
    <div v-if="isAuthenticated">
      <!-- Summry Card -->
      <b-row>
        <b-col
          md="12"
          lg="12"
        >
          <b-card
            text-variant="white"
            bg-variant="primary"
            align="center"
            class="position-static"
          >
            <blockquote class="blockquote  mb-0">
              <p>환전 방법</p>
              <footer class="blockquote-footer text-white opacity-75">
                <small>
                  <cite title="Source Title">{{ exchangeNotice }}</cite>
                </small>
              </footer>
            </blockquote>
          </b-card>
        </b-col>
      </b-row>
      <!-- Exchange Info Card -->
      <div class="row">
        <div class="col-md-8 d-flex flex-column">
          <!-- 왼쪽 col 내용 -->
          <b-card class="h-100">
            <b-col cols="12">
              <b-form-group
                label="환전받을 계좌"
                label-for="hi-first-name"
                label-cols-md="4"
                class="d-flex align-items-center"
              >
                <b-input-group class="input-group-merge">
                  <div v-if="accountNo" class="text-success">
                    {{ accountBank }} {{ accountNo }}
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="받는사람"
                label-for="hi-email"
                label-cols-md="4"
                class="d-flex align-items-center"
              >
                <b-input-group class="input-group-merge">
                  <div v-if="accountNo" class="text-success">
                    {{ username }}
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
            <hr>
            <b-col cols="12">
              <b-form-group
                label="환전금액"
                label-for="hi-password"
                label-cols-md="4"
                class="d-flex align-items-center"
              >
                <b-input-group class="input-group-merge">
                  <div class="text-warning">
                    {{ Number(exchangeAmt).toLocaleString() }}
                  </div>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-card>
        </div>
        <div class="col-md-4 d-flex flex-column">
          <!-- 오른쪽 col 내용 -->
          <b-card class="h-100">
            <b-col class="">
              <div class="justify-content-center">
                <div class="row">
                  <div class="col-6" style="padding-right: 2px">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block"
                      @click="exchangeAmt += 10000"
                    >1만</b-button>
                  </div>
                  <div class="col-6" style="padding-left: 2px">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block"
                      @click="exchangeAmt += 50000"
                    >5만</b-button>
                  </div>
                  <div class="col-6" style="padding-right: 2px">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block"
                      @click="exchangeAmt += 100000"
                    >10만</b-button>
                  </div>
                  <div class="col-6" style="padding-left: 2px">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block"
                      @click="exchangeAmt += 200000"
                    >20만</b-button>
                  </div>
                  <div class="col-6" style="padding-right: 2px">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block"
                      @click="exchangeAmt += 500000"
                    >50만</b-button>
                  </div>
                  <div class="col-6" style="padding-left: 2px">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block"
                      @click="exchangeAmt += 1000000"
                    >100만</b-button>
                  </div>
                  <div class="col-12">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-secondary"
                      class="btn-num btn-block font-small-3"
                      @click="exchangeAmt = 0"
                    >Reset</b-button>
                  </div>
                </div>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-warning"
                  class="btn-block mt-1"
                  @click="confirmExchange()"
                >환전신청</b-button>
              </div>
            </b-col>
          </b-card>
        </div>
      </div>
      <!-- Exchange History -->
      <b-card>
        <b-row>
          <b-col cols="12">
            <!-- bs-table -->
            <b-table
              show-empty
              striped
              hover
              responsive
              class="text-center"
              empty-text="(1) 검색결과가 없습니다."
              empty-filtered-text="(2) 검색결과가 없습니다."
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :items="exchange"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(index)="data">
                {{ totalRows - (perPage * (currentPage - 1)) - data.index }}
              </template>
              <template #cell(createdAt)="data">
                <i>{{ data.value | yyyyMMdd }} </i>
              </template>
              <template #cell(exchange)="data">
                <i>{{ data.value | krw | won }} </i>
              </template>
              <template #cell(userid)="data">
                {{ data.value }}
              </template>
              <template #cell(type)="data">
                <b-badge :variant="type[1][data.value]">
                  {{ type[0][data.value] }}
                </b-badge>
              </template>
            </b-table>
            <!-- bs-table. -->
            <!-- pagination -->
            <b-col cols="12">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                align="center"
                class="my-0"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          <!-- pagination. -->
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormGroup, BButton, BTable, BBadge, BPagination, BInputGroup, BFormInput, VBModal, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'

import { FETCH_EXCHANGE, FETCH_USERS_SMPL } from '@/store/exchange/action'
import { FETCH_SET_DEPOSIT_EXCHANGE } from '@/store/settings/action'
import { UPSERT_REQUEST_ALARMS } from '@/store/settings/mutation'
import { INSERT_EXCHANGE, UPSERT_EXCHANGE } from '@/store/exchange/mutation'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Swal from 'sweetalert2'
import * as moment from 'moment-timezone'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm:ss'
const fmt2 = 'YYYYMMDD'

const authStore = createNamespacedHelpers('authStore')
const exchangeStore = createNamespacedHelpers('exchangeStore')
const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BBadge,
    BPagination,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  // 커스텀 필터 설정
  filters: {
    // filter로 쓸 filter ID 지정
    yyyyMMdd(value) { //  yyyy MM dd 형태로 만들어줄거기 때문에 이렇게 이름을 지었음
      // 들어오는 value 값이 공백이면 그냥 공백으로 돌려줌
      if (value == '') return ''

      // 현재 Date 혹은 DateTime 데이터를 javaScript date 타입화
      const js_date = new Date(value)

      // 연도, 월, 일 추출
      const year = js_date.getFullYear()
      let month = js_date.getMonth() + 1
      let day = js_date.getDate()

      // 월, 일의 경우 한자리 수 값이 있기 때문에 공백에 0 처리
      if (month < 10) {
        month = `0${month}`
      }

      if (day < 10) {
        day = `0${day}`
      }

      // 최종 포맷 (ex - '2021-10-08')
      return `${year}-${month}-${day}`
    },
    krw(value) {
      if (value == '') return ''
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    won(value) {
      if (value == '') return ''
      return `${value} 원`
    },
  },
  data() {
    return {
      uid: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      userInfo: {
        userid: '',
        nickname: '',
        cash: 0,
        point: 0,
        rank: 1,
      },
      username: '',
      accountBack: '',
      accountNumber: '',
      exchangeNotice: '',
      exchangeAmt: 0,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      fields: [
        { key: 'index', label: 'No.' },
        { key: 'createdAt', label: '신청날짜' },
        { key: 'exchange', label: '환전금액' },
        { key: 'userid', label: '출금자명' },
        { key: 'type', label: '상태' },
      ],
      type: [{
        out: '신청', ok: '승인', reject: '부결', cancel: '취소',
      },
      {
        out: 'light-warning', ok: 'light-success', reject: 'light-primary', cancel: 'light-secondary',
      }],
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      accountBank: '',
      accountNo: '',
      accountNo1: '',
      accountName: '',
      depositNotice: '',
      lastExchangeTime: 0,
      password: '', // 사용자가 입력할 패스워드
      passwordError: '', // 패스워드 오류 메시지
      isAuthenticated: false, // 패스워드 인증 여부
    }
  },

  computed: {
    ...exchangeStore.mapGetters([
      'exchange',
      'fetchUsersSmpl',
    ]),
    ...settingsStore.mapGetters([
      'fetchSetDepositExchange',
    ]),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    this.chkPass()
    // 마운트 시점에 패스워드 인증 모달 띄우기
    this.$bvModal.show('passwordModal')
    // this.executeMountedLogic()
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    ...exchangeStore.mapActions({
      $insertExchange: INSERT_EXCHANGE,
      $fetchExchange: FETCH_EXCHANGE,
      $upsertExchange: UPSERT_EXCHANGE,
      $fetchUsersSmpl: FETCH_USERS_SMPL,
    }),
    ...settingsStore.mapActions({
      $upsertRequestAlarms: UPSERT_REQUEST_ALARMS,
      $fetchSetDepositExchange: FETCH_SET_DEPOSIT_EXCHANGE,
    }),
    // 패스워드 확인 메서드
    confirmPassword() {
      const outComePassword = this.fetchUsersSmpl[0].outcomePassword
      if (this.password === outComePassword) {
        this.passwordError = ''
        this.isAuthenticated = true // 인증 성공
        this.executeMountedLogic() // 인증 후 mounted 로직 실행
      } else {
        this.passwordError = 'Incorrect password. Please try again.'
      }
      this.$bvModal.hide('passwordModal') // 모달 닫기
    },
    focusPasswordInput() {
      this.$refs.passwordInput.focus()
    },
    handleCancel() {
      if (this.isAuthenticated) {
        this.executeMountedLogic() // 인증 후 mounted 로직 실행
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: '환전 비밀번호를 확인하세요.',
            icon: 'AlertCircleIcon',
            variant: 'error',
          },
        })
        this.$router.push({ name: 'home' })
      }
    },
    executeMountedLogic() {
      // mounted 로직을 여기서 실행
      this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: JSON.parse(localStorage.getItem('userData')).userid,
        menu: 'exchange',
        menuCategory: '',
      })
      this.userInfo.userid = this.userData.userid
      this.userInfo.username = this.userData.username
      this.userInfo.nickname = this.userData.nickname
      this.userInfo.cash = this.userData.cash
      this.userInfo.point = this.userData.point
      this.userInfo.rank = this.userData.rank

      // ##.Exchange init
      this.fetchExchange()
    },
    createUid() {
      this.uid = moment(new Date())
      // console.log('createUid:uid::', this.uid)
      // console.log('createUid:uid::', moment(new Date()))
    },
    // ##.Exchange init
    async fetchExchange() {
      await this.$fetchExchange({
        site: this.$site,
        userid: this.userData.userid,
      })
      // await this.$fetchUsersSmpl({
      //   site: this.$site,
      //   userid: this.userData.userid,
      // })
      await this.$fetchSetDepositExchange({
        site: this.$site,
      })
      this.totalRows = this.exchange.length
      this.exchangeNotice = this.fetchSetDepositExchange.exchangeNotice

      this.accountBank = this.fetchUsersSmpl[0].accountBank
      this.accountNo = this.fetchUsersSmpl[0].accountNumber
      this.username = this.fetchUsersSmpl[0].username
    },

    showToast(title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'InfoIcon',
          variant: 'info',
        },
      })
    },
    // ##.Exchange validation
    confirmExchange() {
      const cash = Number(this.userData.cash)
      const exchangeAmt = Number(this.exchangeAmt)
      const exchangeMin = Number(this.fetchSetDepositExchange.exchangeMin)
      const exchangeMax = Number(this.fetchSetDepositExchange.exchangeMax)
      const exchangeAmount = Number(this.fetchSetDepositExchange.exchangeAmount)
      const exchangeInterval = Number(this.fetchSetDepositExchange.exchangeInterval)
      const exchangeIntervalMs = Number(exchangeInterval * 60000)
      const lastArr = Number(this.exchange.length - 1)
      if (lastArr === -1) {
        this.lastExchangeTime = 0
      } else {
        this.lastExchangeTime = this.exchange[lastArr].createdAt
      }
      const currentTime = new Date().getTime()
      const timeDiff = currentTime - this.lastExchangeTime
      if (cash < 1) {
        this.showToast('환전 금액이 없습니다.')
      } else if (cash < exchangeAmt) {
        this.showToast('보유 금액이 부족합니다.')
      } else if (exchangeAmt < exchangeMin) {
        this.showToast(`최소 환전 금액은 ${exchangeMin.toLocaleString()} 원 입니다.`)
      } else if (exchangeMax < exchangeAmt) {
        this.showToast(`최대 환전 금액은 ${exchangeMax.toLocaleString()} 원 입니다.`)
      } else if (exchangeAmt % exchangeAmount !== 0) {
        this.showToast(`${exchangeAmount.toLocaleString()} 원 단위로 입력해 주세요.`)
      } else if (timeDiff < exchangeIntervalMs) {
        this.showToast(`환전 주기는 ${exchangeInterval} 분 입니다.`)
      } else {
      // Exchange validation : Success
        Swal.fire({
          title: '환전 신청 하시겠습니까?',
          text: Number(this.exchangeAmt).toLocaleString(),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Go Exchange!',
          background: 'rgb(3,11,37)',
          customClass: {
            cancelButton: 'btn btn-dark',
            confirmButton: 'btn btn-primary mr-1',
          },
        }).then(result => {
        // Exchange Confirm
          if (result.isConfirmed) {
            this.createUid()
            this.requestExchange()
          }
        })
      }
    },

    // ##.Exchange Save Excute
    async requestExchange() {
      const mySite = this.userData.site
      const myId = this.userData.userid
      const ip = this.userIp
      const requestExchangeData = {
        site: mySite,
        userid: myId,
        exchange: Number(this.exchangeAmt),
        type: 'out',
        exchangeType: 'sports',
        userBank: this.accountBank,
        userAccount: this.accountNo,
        adminBank: '',
        adminAccount: '',
        userIp: ip,
        uid: `${mySite}:${myId}:${this.exchangeAmt}:${this.uid}`,
      }
      await this.$insertExchange({
        ...requestExchangeData,
      })
      Swal.fire({
        title: '환전신청완료',
        text: `${Number(this.exchangeAmt).toLocaleString()}원`,
        icon: 'success',
        timer: 5000,
        background: 'rgb(3,11,37)',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      }).then(result => {
        if (result.isConfirmed) {
          this.fetchExchange()
          this.exchangeAmt = 0
        }
      })
    },
    async chkPass() {
      await this.$fetchUsersSmpl({
        site: this.$site,
        userid: this.userData.userid,
      })
    },
  },
}
</script>
<style lang="scss">
  .btn-num {
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    margin-bottom: 5px;
    //box-shadow: 0 0 3px #eee;
    border-radius: 5px;
    //background-image: linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%);
    background-image: linear-gradient(to right, #2b353f 0%, #353f68 51%, #273e5f 100%);

    &:hover {
      background-position: right center; /* change the direction of the change here */
      text-align: center;
    }
  }
  .custom-modal-position {
    margin-top: 500px !important;
  }
</style>
